table.changes {
  width: 100%;
  margin: 16px auto;
}
table.changes td,
table.changes th {
  padding: 4px;
  border-bottom: 1px solid #eee;
}
table.changes th {
  border-bottom: 2px solid #ddd;
}
