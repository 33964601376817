.grid-cell-tooltip-validations {
  position: absolute;
  width: 150px;
  height: 150px;
  /*//border: 1px solid #333;*/
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
  /*background-color: #ddd;*/
  border-radius: 4px;
}
.tooltip-shown {
  background-color: #ddd;
}
.grid-cell-tooltip-validations.ag-tooltip-hiding {
  opacity: 0;
}
