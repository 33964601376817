.targets-table-duid-bid-types {
  width: 100%;
}
.targets-table-duid-bid-types td,
.targets-table-duid-bid-types th {
  padding: 4px;
  border: 1px solid #eee;
}
.targets-table-duid-bid-types th {
  border-bottom: 2px;
}
