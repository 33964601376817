/*Output table*/
.bid-allocator .output td,
.bid-allocator .output th {
  padding: 8px 16px;
  border-bottom: 1px solid #ccc;
  text-align: right;
}
/*Input table*/
.bid-allocator table.input td,
.bid-allocator table.input th {
  padding: 8px 16px;
  border-bottom: 1px solid #ccc;
}

/*Logs Table*/
.bid-allocator table.logs {
  /*width: 90%;*/
}
.bid-allocator table.logs td {
  padding: 4px 8px;
  border-bottom: 1px solid #ccc;
}
.bid-allocator table.logs td.idx {
  min-width: 50px;
  text-align: right;
}
.bid-allocator table.logs td.msg {
  min-width: 200px;
}
.bid-allocator table.logs td.code {
  min-width: 500px;
  max-width: 800px;
}
